
/* eslint-disable @typescript-eslint/camelcase */
import {
  defineComponent,
  PropType,
  ref,
  toRefs,
  unref,
  watch,
  Ref
} from 'vue';
import { ElForm, ElMessage } from 'element-plus';

import { VideoItem } from '@/components/video-uploader/Index.vue';
import { createVideo, updateVideo } from '@/services/api';
import { PartialVideo } from '@/interfaces/Video';

const rules = {
  serial_number: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  title: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
};

export default defineComponent({
  props: {
    video: {
      type: Object as PropType<VideoItem>,
      required: true
    }
  },
  setup(props) {
    const video = toRefs(props).video as Ref<VideoItem>;

    const formRef = ref<typeof ElForm>();

    let preSerialNumber = video.value.uuid;
    const formValues = ref<PartialVideo>({
      serial_number: preSerialNumber,
      title: video.value.file.name
    });

    watch(() => video.value.path, async(path) => {
      await createVideo({
        data: {
          serial_number: formValues.value.serial_number,
          title: formValues.value.title,
          video_source: path
        }
      });

      ElMessage.success(`${formValues.value.title} create successfully`);
    });

    const isLoading = ref(false);
    const update = () => {
      const form = unref(formRef);

      form && form.validate(async(valid: boolean) => {
        if (valid) {
          isLoading.value = true;

          try {
            await updateVideo({
              videoId: preSerialNumber,
              data: {
                serial_number: formValues.value.serial_number,
                title: formValues.value.title,
                video_source: video.value.path
              }
            });
          } catch (error) {
            isLoading.value = false;

            ElMessage.error((error as any).response?.data.message);
            return;
          }

          preSerialNumber = formValues.value.serial_number;
          isLoading.value = false;

          ElMessage.success('Update successfully');
        }
      });
    };

    return {
      formRef,
      rules,
      formValues,
      isLoading,
      update
    };
  }
});
